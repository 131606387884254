<template>
    <div>
        <b-container>
            <b-row>
                <b-col>
                    <div>
                        <strong>Register {{instructor.fullname}} for Instructor Sessions</strong>
                        <br /><br />
                    </div>
                </b-col>
            </b-row>
            <b-row>
                <b-col>
                    <div>
                        <b-table small striped hover bordered
                                 stacked="sm" head-variant="dark"
                                 :items="registrableSessions"
                                 :fields="fields" >
                            <template v-slot:cell(session)="row">
                                <b-link
                                        @click="getDetails(row.item.session)">{{row.item.session.label}} {{row.item.session.availableSeatsLabel}}</b-link>
                            </template>
                            <template v-slot:cell(register)="row">
                                <b-dropdown
                                        :variant="statusVariant(row.item.status)"
                                        :text=row.item.status
                                        size="sm">
                                    <b-dropdown-item
                                            href="#" class="options"
                                            @click="register(row.item)">Register</b-dropdown-item>
                                </b-dropdown>
                            </template>
                        </b-table>
                        <template v-if="!registrableSessions.length">
                            No registrable sessions found for this instructor
                        </template>
                    </div>
                </b-col>
            </b-row>
        </b-container>
        <!-- CONFIRM REGISTRATION MODAL -->
        <b-modal :id="registrationModalId"
                 size="xl"
                 scrollable
                 noCloseOnBackdrop
                 noCloseOnEsc
                 hideHeaderClose
                 hideFooter
                 title="Confirm Registration"
                 header-bg-variant="dark"
                 header-text-variant="white">
            <ConfirmRegistration
                    :fromTravel="fromTravel"
                    :registrationRequest="registrationRequest"
                    :requestType="requestType"
                    @registered="registered"
                    @canceled="canceled" />
        </b-modal>
        <!-- SESSION DETAILS MODAL -->
        <b-modal :id="sessionDetailsModalId"
                 size="xl"
                 scrollable
                 ok-only
                 title="Session Details"
                 header-bg-variant="dark"
                 header-text-variant="white">
            <SessionDetails
                    :session="session" />
        </b-modal>
    </div>
</template>

<script>
    import { Prop, Component, Vue} from 'vue-property-decorator';
    import {sprintf} from 'sprintf-js';
    import _ from 'underscore';
    import {Status, RegistrationRequestType} from "@/model/registration";
    import {User, Instructor, NULL_USER} from "@/model/user";
    import ConfirmRegistration from "@/views/private/tracks/ConfirmRegistration.vue";
    import SessionDetails from '@/views/private/tracks/SessionDetails.vue';
    import {errorToastOptions} from "../../../util/formatters";
    import covidRegistrationRestriction from '@/errors/covid_registration_restriction';

    @Component({
        components: {
            ConfirmRegistration,
            SessionDetails
        },
        asyncComputed: {},
        methods: {}
    })

    export default class InstructorSessionRegistration extends Vue {
        @Prop({type: [Instructor, User], default: NULL_USER}) instructor;
        @Prop({type: Boolean, default: false}) fromTravel;
        registrationRequest = null;
        session = null;

        get requestType() {
            return RegistrationRequestType.INSTRUCTOR_SESSION_REGISTRATION;
        }

        get registrationModalId() {
            return sprintf('confirm-instructor-registration-modal-%d', this.instructor.id);
        }

        get sessionDetailsModalId() {
            if (_.isNull(this.session)) {
                return 'session-details-modal-invalid';
            }
            else {
                return sprintf('session-details-modal-%d', this.session.sessionId);
            }
        }

        getDetails(session) {
            this.session = session;
            this.$bvModal.show(this.sessionDetailsModalId);
        }

        registered(details) {
            this.$bvModal.hide(this.registrationModalId);
            this.registrationRequest = null;
            // console.log(details);
            this.$emit('done', details);
        }

        canceled() {
            this.$bvModal.hide(this.registrationModalId);
            this.registrationRequest = null;
        }

        async mounted() {
            try {
                await this.$store.dispatch('instructor/loadInstructorSessions');
            }
            catch (error) {
                this.$bvToast.toast(error.message, errorToastOptions);
            }
        }

        get registrableSessions() {
            if (_.isNull(this.instructor) || _.isUndefined(this.instructor) || _.isEqual(this.instructor, NULL_USER)) {
                return [];
            }
            const scheduleItems = this.$store.getters['instructor/registrableInstructorSessions'](this.instructor);
            return scheduleItems;
        }

        get fields() {
            return [{
                key: 'session',
                label: 'Session'
            }, {
                key: 'register',
                label: 'Register'
            }];
        }

        statusVariant(status) {
            return Status.variant(status);
        }

        async register(scheduleItem) {
            // console.log(scheduleItem);
            //Check for override
            if (scheduleItem.needsOverride) {
                const activeUser = this.$store.getters['userSession/getUser'];
                if (activeUser.isAnAdministrator()) {
                    const check = await this.$bvModal.msgBoxConfirm('The participant you are attempting to ' +
                        'register for a session that requires vaccine verification has not been verified. Would you ' +
                        'like to override the vaccine requirement and register this participant anyway?', {
                        title: 'Vaccine Override Needed',
                        noCloseOnBackdrop: true,
                        noCloseOnEsc: true,
                        headerBgVariant: 'dark',
                        headerTextVariant: 'white'
                    });
                    if (check) {
                        //Set override flag
                        scheduleItem.registration.vaccineOverride = true;
                    } else {
                        return;
                    }
                } else {
                    //A non-admin cannot override
                    return this.$bvModal.msgBoxOk(covidRegistrationRestriction(this), {
                        size: 'md',
                        buttonSize: 'sm'
                    });
                }
            }
            this.registrationRequest = scheduleItem;
            this.$bvModal.show(this.registrationModalId);
        }
    }
</script>

<style scoped>
    b-dropdown-item.options {
    }
</style>

