<template>
    <div>OJT snippet</div>
</template>
<script>

export default {
    name: 'OJTSnippet'
};


</script>
<style>

</style>
