<template>
    <div>
        <b-row>
            <b-col cols="6">
                <b-form-group
                        label-for="type-filter"
                        label="Activity Type:">
                    <b-select
                            id="type-filer"
                            v-model="typeFilter"
                            :options="activityTypes"
                            @change="refreshActivities" />
                </b-form-group>
                <b-form-group
                        label-for="display-id-filer"
                        label="Display ID:">
                    <b-input
                            id="display-id-filter"
                            v-model="displayIdFilter"
                            @input="refreshActivities" />
                </b-form-group>
            </b-col>
            <b-col cols="6">
                <b-form-group
                        label-for="title-filer"
                        label="Title:">
                    <b-input
                            id="title-filter"
                            v-model="titleFilter"
                            @input="refreshActivities" />
                </b-form-group>
                <b-form-group
                        label-for="description-filer"
                        label="Description:">
                    <b-input
                            id="description-filter"
                            v-model="descriptionFilter"
                            @input="refreshActivities" />
                </b-form-group>
            </b-col>
        </b-row>
        <b-row>
            <b-col cols="12">
                <b-table small striped hover bordered responsive
                         stacked="sm"
                         head-variant="dark"
                         :items="activities"
                         :fields="fields">
                    <template v-slot:cell(id)="row">
                        <template
                                v-if="row.item.isCustomActivity()">{{row.item.customDisplayId}}</template>
                        <template
                                v-else>{{row.item.displayId}}</template>
                    </template>
                    <template v-slot:cell(title)="row">
                        {{row.item.title}}
                    </template>
                    <template v-slot:cell(description)="row">
                        {{row.item.description}}
                    </template>
                    <template v-slot:cell(scheduledDate)="row">
                        <CITFDatePicker
                                v-model="row.item.workspace.scheduled"
                                :width="100"
                                type="month"
                                format="MMM YYYY" />
                    </template>
                    <template v-slot:cell(action)="row">
                        <b-button
                                @click="assign(row.item)">Add to schedule</b-button>
                    </template>
                </b-table>
            </b-col>
        </b-row>
    </div>
</template>

<script>
    import { Prop, Component, Vue} from 'vue-property-decorator';
    import {sprintf} from 'sprintf-js';
    import _ from 'underscore';
    import {User, Trainee, NULL_USER} from "@/model/user";
    import store from '@/store/store';
    import userDao from '@/dao/user_dao';
    import {ActivityFactory, Activity} from "@/model/activity";
    import CITFDatePicker from '@/components/shared/CITFDatePicker.vue';
    import {endOfMonth, set} from "date-fns";
    import {errorModalOptions, errorToastOptions} from "../../../util/formatters";

    @Component({
        components: {
            CITFDatePicker
        },
        asyncComputed: {
            traineeScheduledActivities: {
                async get() {
                    if (!User.isValidUser(this.trainee)) {
                        return [];
                    }
                    else {
                        try {
                            await store.dispatch('activities/loadTraineeActivitySchedule', this.trainee);
                        }
                        catch (error) {
                            this.$bvToast.toast(error.message, errorToastOptions);
                        }
                        const activities = store.getters['activities/traineeActivitySchedule'](this.trainee);
                        return activities;
                    }
                }
            }
        },
        methods: {
            refreshActivities() {
                // this.$asyncComputed.traineeScheduledActivities.update();
                // this.$asyncComputed.activities.update();
            }
        }
    })

    export default class ActivityAssignment extends Vue {
        @Prop({type: [Trainee, User], default: NULL_USER}) trainee;
        typeFilter = 'All';
        displayIdFilter = null;
        titleFilter = null;
        descriptionFilter = null;

        get currentGeneration() {
            const currentGeneration = store.getters['activities/currentActivityGeneration'];
            return currentGeneration;
        }

        get mas() {
            const mas = store.getters['activities/getMAs'];
            return mas;
        }

        get ojts() {
            const ojts = store.getters['activities/getOJTs'];
            return ojts;
        }

        get customMas() {
            const customMas = store.getters['activities/getCustomMAs'];
            return customMas;
        }

        get customOjts() {
            const customOjts = store.getters['activities/getCustomOJTs'];
            return customOjts;
        }

        get allActivities() {
            const activities = _.union(this.mas, this.ojts, this.customMas, this.customOjts);
            return activities;
        }

        get scheduledStandardActivityIds() {
            if (!User.isValidUser(this.trainee)) {
                return [];
            }
            const scheduledActivityIds = _.chain(this.traineeScheduledActivities)
                .filter(activity => {
                    return !activity.isCustomActivity();
                })
                .map(scheduledActivity => {
                    return scheduledActivity.activityId;
                })
                .value();
            return scheduledActivityIds;
        }

        get scheduledCustomActivityIds() {
            if (!User.isValidUser(this.trainee)) {
                return [];
            }
            const scheduledActivityIds = _.chain(this.traineeScheduledActivities)
                .filter(activity => {
                    return activity.isCustomActivity();
                })
                .map(scheduledActivity => {
                    return scheduledActivity.customActivityId;
                })
                .value();
            return scheduledActivityIds;
        }

        get activities() {
            const filteredActivities = _.chain(this.allActivities)
                .filter(activity => {
                    return activity.isCustomActivity() ?
                        //Don't include non-approved, non-active, or cancelled custom activities
                        !!activity.isApproved && !!activity.active && !activity.cancelled :
                        //Don't include past generation standard activities
                        activity.generation === this.currentGeneration;
                })
                .filter(activity => {
                    //Don't include already-assigned activities
                    return activity.traineeId === 0;
                })
                .filter(activity => {
                    //Don't include already-assigned activities
                    return activity.isCustomActivity() ?
                        !this.scheduledCustomActivityIds.includes(activity.customActivityId) :
                        !this.scheduledStandardActivityIds.includes(activity.activityId);
                })
                .filter(activity => {
                    switch (this.typeFilter) {
                        case 'OJT':
                            return activity.isOJT();
                        case 'MA':
                            return activity.isMA();
                        case 'Custom - All':
                            return activity.isCustomActivity();
                        case 'Custom - OJT':
                            return activity.isCustomActivity() && activity.isCustomOJT();
                        case 'Custom - MA':
                            return activity.isCustomActivity() && activity.isCustomMA();
                        default:
                            return true;
                    }
                })
                .filter(activity => {
                    const filter = _.isNull(this.displayIdFilter) ?
                        null : new RegExp(this.displayIdFilter, 'i');
                    const displayId = activity.isCustomActivity() ? activity.customDisplayId : activity.displayId;
                    return _.isNull(filter) || filter.test(displayId);
                })
                .filter(activity => {
                    const filter = _.isNull(this.titleFilter) ?
                        null : new RegExp(this.titleFilter, 'i');
                    return _.isNull(filter) || filter.test(activity.title);
                })
                .filter(activity => {
                    const filter = _.isNull(this.descriptionFilter) ?
                        null : new RegExp(this.descriptionFilter, 'i');
                    return _.isNull(filter) || filter.test(activity.description);
                })
                .value();
            // console.log(filteredActivities);
            return filteredActivities;
        }

        async mounted() {
            try {
                await store.dispatch('activities/loadMAs');
                await store.dispatch('activities/loadOJTs');
                await store.dispatch('activities/loadCustomMAs');
                await store.dispatch('activities/loadCustomOJTs');
            }
            catch (error) {
                this.$bvToast.toast(error.message, errorToastOptions);
            }
            // console.log(this.trainee);
        }

        async assign(activity) {
            // console.log(activity);
            //Double-check
            const assignCheck = await this.$bvModal.msgBoxConfirm(sprintf(
                'Are you sure you want to assign %s to %s\'s schedule?',
                activity.label, this.trainee.fullname), {
                    title: 'Confirm Assignment',
                    noCloseOnBackdrop: true,
                    noCloseOnEsc: true,
                    headerBgVariant: 'dark',
                    headerTextVariant: 'white'
                });
            if (!assignCheck) {
                return;
            }
            //Make sure activity has a workspace
            if (_.isNull(activity.workspace) || _.isUndefined(activity.workspace)) {
                activity.workspace = ActivityFactory.create(activity, true);
            }
            //if date is not null, set day of selected month/year to last day of the month
            if (!!activity.workspace.scheduled) {
                activity.workspace.scheduled = set(
                    endOfMonth(activity.workspace.scheduled),
                    { hours: 0, minutes: 0, seconds: 0, milliseconds: 0 });
            }
            //Add IDs to activity to make association
            activity.workspace.traineeId = await this.getTraineeId();
            activity.workspace.mentorProfileId = this.trainee.mentorId;
            activity.workspace.traineeActivityId = 0;  //ID 0 flags new entry
            // console.log('Processing trainee activity (changes in workspace):');
            // console.log(activity);
            try {
                await store.dispatch('activities/processTraineeActivity', activity.workspace);
                activity.commit();
                this.$emit('assignment');
                this.refreshActivities();
            }
            catch (error) {
                await this.$bvModal.msgBoxOk(error.message, errorModalOptions);
            }
        }

        async getTraineeId() {
            try {
                const traineeId = await userDao.getTraineeId(this.trainee.id);
                return traineeId;
            }
            catch (error) {
                this.$bvToast.toast(error.message, errorToastOptions);
            }
        }

        get activityTypes() {
            return ['All', 'OJT', 'MA', 'Custom - All', 'Custom - OJT', 'Custom - MA'];
        }

        get fields() {
            const baseFields = [{
                key: 'id',
                label: 'ID',
                sortable: true
            }, {
                key: 'title',
                label: 'Title',
                sortable: true
            }, {
                key: 'description',
                label: 'Description',
                sortable: true
            }, {
                key: 'scheduledDate',
                label: 'Scheduled Date (optional)',
                sortable: true
            }, {
                key: 'action',
                label: 'Action',
                sortable: false
            }];
            return baseFields;
        }
    }
</script>

<style>

</style>