<template>
    <div>
        <b-container>
            <b-row>
                <b-col cols="12">
                    <b-form-group
                            label-for="this-trainee"
                            label="Copy From Trainee:">
                        <b-input
                                id="this-trainee"
                                v-model="trainee.userLabel"
                                size="sm"
                                disabled />
                    </b-form-group>
                </b-col>
            </b-row>
            <b-row>
                <b-col cols="12">
                    <b-form-group
                            label-for="that-trainee"
                            label="Copy To Trainee:"
                            :state="null !== selectedTrainee"
                            invalid-feedback="Required">
                        <b-select
                                id="that-trainee"
                                :options="assignees"
                                v-model="selectedTrainee"
                                :state="null !== selectedTrainee"
                                size="sm" />
                    </b-form-group>
                </b-col>
            </b-row>
            <b-row>
                <b-col cols="12">
                    <b-form-group
                            label-for="include-dates"
                            :state="null !== includeDates"
                            invalid-feedback="Required">
                        <b-form-radio-group
                                id="include-dates"
                                :options="dateOptions"
                                v-model="includeDates"
                                :state="null !== includeDates"
                                size="sm" />
                    </b-form-group>
                </b-col>
            </b-row>
            <b-row>
                <b-col cols="12">
                    <b-button-group
                            class="float-right">
                        <b-button
                                @click="$emit('done')"
                                variant="danger">Cancel</b-button>
                        <b-button
                                @click="copySchedule"
                                :disabled="disableCopySchedule"
                                variant="primary">Copy Schedule</b-button>
                    </b-button-group>
                </b-col>
            </b-row>
        </b-container>
    </div>
</template>

<script>
    import { Prop, Component, Vue} from 'vue-property-decorator';
    import {sprintf} from 'sprintf-js';
    import _ from 'underscore';
    import {User, Trainee, NULL_USER} from "@/model/user";
    import store from '@/store/store';
    import userDao from '@/dao/user_dao';
    import Activity from '@/model/activity';
    import {ActivityFactory} from "../../../model/activity";
    import {errorModalOptions, errorToastOptions} from "../../../util/formatters";

    @Component({})

    export default class CopyTraineeSchedule extends Vue {
        @Prop({type: [Trainee, User], default: NULL_USER}) trainee;
        selectedTrainee = null;
        includeDates = null;

        async mounted() {
            try {
                await store.dispatch('users/loadAvailableTrainees');
            }
            catch (error) {
                this.$bvToast.toast(error.message, errorToastOptions);
            }
        }

        get assignees() {
            const trainees = store.getters['users/availableTrainees'];
            const assignees = _.chain(trainees)
                .filter(assignee => {
                    return !_.isEqual(assignee.id, this.trainee.id);
                })
                .sortBy(assignee => {
                    return assignee.userLabel;
                })
                .map(assignee => {
                    return {
                        text: assignee.userLabel,
                        value: assignee
                    };
                })
                .value();
            return assignees;
        }

        get dateOptions() {
            return [{
                text: 'Include scheduled dates',
                value: true
            }, {
                text: 'Do not include scheduled dates',
                value: false
            }];
        }

        get disableCopySchedule() {
            return _.isNull(this.selectedTrainee) || _.isNull(this.includeDates);
        }

        async copySchedule() {
            const copyScheduleRequest = {
                fromTraineeId: this.trainee.id,
                toTraineeId: this.selectedTrainee.id,
                includeDates: this.includeDates
            };
            // console.log('Copy trainee schedule request:');
            // console.log(copyScheduleRequest);
            try {
                await this.$store.dispatch('activities/copyTraineeSchedule', copyScheduleRequest);
                await this.$bvModal.msgBoxOk('Schedule copied successfully', {
                    title: 'Success',
                    noCloseOnBackdrop: true,
                    noCloseOnEsc: true,
                    hideHeaderClose: true,
                    headerBgVariant: 'dark',
                    headerTextVariant: 'white'
                });
                this.$emit('done');
            }
            catch (error) {
                await this.$bvModal.msgBoxOk(error.message, errorModalOptions);
            }
        }
    }
</script>

<style>

</style>