<template>
    <div>
        <b-row>
            <b-col>
                <div class="alert alert-secondary">
                    <h4>Instructor Schedule for {{selectedInstructor.fullname}}</h4>
                    <br />
                    <template v-if="userIsRegistrable">
                        <b-button
                                variant="primary" size="sm"
                                @click="registration">Register</b-button>
                        <br />
                    </template>
                </div>
            </b-col>
        </b-row>
        <b-row>
            <b-col>
                <b-table-lite hover striped small
                              stacked="sm"
                              head-variant="dark"
                              table-variant="primary"
                              :fields="fields"
                              :items="schedule">
                    <template v-slot:cell(session)="row">
                        <b-link
                                class="session"
                                @click="getDetails(row.item.session)">{{row.item.session.label}}</b-link>
                    </template>
                    <template v-slot:cell(status)="row">
                        <!--It takes too long for the store calls to return for UI rendering registration option verification-->
                        <!--These steps are delegated to the called methods-->
                        <!--Additionally, instructor registration status doesn't include scheduled (no relation to tracks)-->
                        <b-dropdown
                                :variant="statusVariant(row.item.status)"
                                :text=row.item.status
                                size="sm">
                            <template v-if="isRegistered(row.item.status)">
                                <b-dropdown-item
                                        href="#"
                                        @click="cancel(row.item)">Cancel registration</b-dropdown-item>
                                <b-dropdown-item
                                        href="#"
                                        v-if="allowCompleteIncomplete"
                                        @click="incomplete(row.item)">Mark incomplete</b-dropdown-item>
                            </template>
                            <template v-else-if="isCompleted(row.item.status) && allowCompleteIncomplete">
                                <b-dropdown-item
                                        href="#"
                                        @click="incomplete(row.item)">Mark incomplete</b-dropdown-item>
                            </template>
                            <template v-else-if="isIncomplete(row.item.status) && allowCompleteIncomplete">
                                <b-dropdown-item
                                        href="#"
                                        @click="complete(row.item)">Mark complete</b-dropdown-item>
                            </template>
                            <template v-else-if="isCancelled(row.item.status) && isRegistrable(row.item.session) && userIsRegistrable">
                                <b-dropdown-item
                                        href="#"
                                        @click="registerFor(row.item)">Register</b-dropdown-item>
                            </template>
                            <template v-else>
                                <b-dropdown-item>No actions available</b-dropdown-item>
                            </template>
                        </b-dropdown>
                    </template>
                </b-table-lite>
            </b-col>
        </b-row>
        <!-- SESSION DETAILS MODAL -->
        <b-modal :id="sessionDetailsModalId"
                 size="xl"
                 scrollable
                 ok-only
                 title="Session Details"
                 header-bg-variant="dark"
                 header-text-variant="white">
            <SessionDetails
                    :session="session" />
        </b-modal>
        <!--REGISTRATION MODAL-->
        <b-modal :id="registrationModalId"
                 size="xl"
                 scrollable
                 title="Registration"
                 header-bg-variant="dark"
                 header-text-variant="white">
            <InstructorSessionRegistration
                    :instructor="selectedInstructor"
                    @done="closeRegistration" />
        </b-modal>
        <!-- CONFIRM REGISTRATION MODAL -->
        <b-modal :id="confirmRegistrationModalId"
                 size="xl"
                 scrollable
                 noCloseOnBackdrop
                 noCloseOnEsc
                 hideHeaderClose
                 hideFooter
                 title="Confirm Registration"
                 header-bg-variant="dark"
                 header-text-variant="white">
            <ConfirmRegistration
                    :registrationRequest="registrationRequest"
                    :requestType="requestType"
                    @registered="registered"
                    @canceled="canceled" />
        </b-modal>
    </div>
</template>
<script>

import { Vue, Component, Prop } from 'vue-property-decorator';
import {Status} from '@/model/registration';
import InstructorSessionRegistration from '@/views/private/instructor/InstructorSessionRegistration.vue';
import _ from 'underscore';
import formatters from '@/util/formatters';
import {sprintf} from "sprintf-js";
import SessionDetails from '@/views/private/tracks/SessionDetails.vue';
import {errorModalOptions, errorToastOptions} from "../../../util/formatters";
import ConfirmRegistration from "@/views/private/tracks/ConfirmRegistration.vue";
import {RegistrationRequestType} from "../../../model/registration";
import covidRegistrationRestriction from '@/errors/covid_registration_restriction';

@Component({
    filters: {
        dateFormatter: formatters.date
    },
    components: {
        InstructorSessionRegistration,
        SessionDetails,
        ConfirmRegistration
    },
    asyncComputed: {
        schedule: {
            async get() {
                if (!_.isNull(this.instructorId) && !_.isUndefined(this.instructorId)) {
                    try {
                        //Update instructor
                        await this.$store.dispatch('instructor/loadInstructor', this.instructorId);
                        //Update schedule
                        await this.$store.dispatch('instructor/loadInstructorSessions');
                    }
                    catch (error) {
                        this.$bvToast.toast(error.message, errorToastOptions);
                    }
                    const schedule = this.$store.getters['instructor/instructorSchedule'](this.selectedInstructor);
                    // console.log(schedule);
                    return schedule;
                }
                return [];
            }
        }
    },
    methods: {
        refreshSchedule() {
            this.$asyncComputed.schedule.update();
        }
    }
})

export default class InstructorSchedule extends Vue {
    @Prop({type: [Number, String]}) instructorId;
    registrationRequest = null;
    session = null;

    registered() {
        this.refreshSchedule();
        this.$bvModal.hide(this.confirmRegistrationModalId);
        this.registrationRequest = null;
    }

    canceled() {
        this.$bvModal.hide(this.confirmRegistrationModalId);
        this.registrationRequest = null;
    }

    get confirmRegistrationModalId() {
        return 'confirm-registration-modal-' + this.instructorId;
    }

    async registerFor(scheduleItem) {
        // console.log(scheduleItem);
        if (!this.userIsRegistrable || !this.isCancelled(scheduleItem.status)) {
            await this.$bvModal.msgBoxOk('Sorry, user is not eligible to register for this session.');
        }
        else {
            //Check for override
            if (scheduleItem.needsOverride) {
                const activeUser = this.$store.getters['userSession/getUser'];
                if (activeUser.isAnAdministrator()) {
                    const check = await this.$bvModal.msgBoxConfirm('The participant you are attempting to ' +
                        'register for a session that requires vaccine verification has not been verified. Would you ' +
                        'like to override the vaccine requirement and register this participant anyway?', {
                        title: 'Vaccine Override Needed',
                        noCloseOnBackdrop: true,
                        noCloseOnEsc: true,
                        headerBgVariant: 'dark',
                        headerTextVariant: 'white'
                    });
                    if (check) {
                        //Set override flag
                        scheduleItem.registration.vaccineOverride = true;
                    } else {
                        return;
                    }
                } else {
                    //A non-admin cannot override
                    return this.$bvModal.msgBoxOk(covidRegistrationRestriction(this), {
                        size: 'md',
                        buttonSize: 'sm'
                    });
                }
            }
            this.registrationRequest = scheduleItem;
            this.registrationRequest.registration.canceled = false;
            this.$bvModal.show(this.confirmRegistrationModalId);
        }
    }

    get sessionDetailsModalId() {
        if (_.isNull(this.session)) {
            return 'session-details-modal-invalid';
        }
        else {
            return sprintf('session-details-modal-%d', this.session.sessionId);
        }
    }

    getDetails(session) {
        this.session = session;
        this.$bvModal.show(this.sessionDetailsModalId);
    }

    get selectedInstructor() {
        return this.$store.getters['instructor/instructor'];
    }

    get userIsRegistrable() {
        //Instructors are always registrable
        return true;
    }

    get registrationModalId() {
        if (_.isNull(this.instructorId) || _.isNaN(this.instructorId) || 0 === this.instructorId) {
            return 'instructor-registration-modal-invalid';
        }
        else {
            return 'instructor-registration-modal-' + this.instructorId;
        }
    }

    registration() {
        this.$bvModal.show(this.registrationModalId);
    }

    async closeRegistration() {
        this.$bvModal.hide(this.registrationModalId);
        await this.addRegistrationAction();
    }

    get fields() {
        return [{
            key: 'session',
            label: 'Session'
        }, {
            key: 'status',
            label: 'Status'
        }];
    }

    statusVariant(status) {
        return Status.variant(status);
    }

    isRegistered(status) {
        return _.isEqual(status, Status.REGISTERED);
    }

    isCompleted(status) {
        return _.isEqual(status, Status.COMPLETED);
    }

    isIncomplete(status) {
        return _.isEqual(status, Status.INCOMPLETE);
    }

    isCancelled(status) {
        return _.isEqual(status, Status.CANCELED);
    }

    isRegistrable(session) {
        // console.log(session);
        const activeUser = this.$store.getters['userSession/getUser'];
        return activeUser.isAnAdministrator() ?
            !!session :
            !!session && !!session.allowRegistrations && !session.adminRegistration;
    }

    get requestType() {
        return RegistrationRequestType.INSTRUCTOR_SESSION_REGISTRATION;
    }

    get allowCompleteIncomplete() {
        //Per Rocio 3/19, no need to implement instructor incomplete/complete registration status
        return false;
        // //Don't offer the option to mark a session registration incomplete unless user is admin
        // return this.$store.getters['userSession/isAnAdmin'];
    }

    async incomplete(scheduleItem) {
        const check = await this.$bvModal.msgBoxConfirm(
            'Are you sure you wish to make this session registration incomplete?', {
                title: 'Confirm Incomplete',
                noCloseOnBackdrop: true,
                noCloseOnEsc: true,
                headerBgVariant: 'dark',
                headerTextVariant: 'white'
            });
        if (!check) {
            return;
        }
        //Update registration - note that only one of complete/incomplete should have a value
        //Consider implementing workspace
        const oldComplete = scheduleItem.registration.complete;
        const oldIncomplete = scheduleItem.registration.incomplete;
        scheduleItem.registration.complete = null;
        scheduleItem.registration.incomplete = new Date();
        try {
            await this.$store.dispatch('instructor/processRegistration', scheduleItem, {root: true});
        }
        catch (error) {
            await this.$bvModal.msgBoxOk(error.message, errorModalOptions);
            scheduleItem.registration.complete = oldComplete;
            scheduleItem.registration.incomplete = oldIncomplete;
        }
    }

    async complete(scheduleItem) {
        const check = await this.$bvModal.msgBoxConfirm(
            'Are you sure you wish to make this session registration completed?', {
                title: 'Confirm Completed',
                noCloseOnBackdrop: true,
                noCloseOnEsc: true,
                headerBgVariant: 'dark',
                headerTextVariant: 'white'
            });
        if (!check) {
            return;
        }
        //Update registration - note that only one of complete/incomplete should have a value
        //Consider implementing workspace
        const oldComplete = scheduleItem.registration.complete;
        const oldIncomplete = scheduleItem.registration.incomplete;
        scheduleItem.registration.complete = new Date();
        scheduleItem.registration.incomplete = null;
        try {
            await this.$store.dispatch('instructor/processRegistration', scheduleItem, {root: true});
        }
        catch (error) {
            await this.$bvModal.msgBoxOk(error.message, errorModalOptions);
            scheduleItem.registration.complete = oldComplete;
            scheduleItem.registration.incomplete = oldIncomplete;
        }
    }

    async cancel(scheduleItem) {
        // console.log(scheduleItem);
        if (!this.userIsCancelable || !this.isCancellable(scheduleItem)) {
            await this.$bvModal.msgBoxOk('Sorry, user is not eligible to cancel registration for this session.');
        }
        else {
            await this.cancelModal(scheduleItem);
        }
    }

    get userIsCancelable() {
        //Instructor can cancel for self, admin can cancel for all users
        return true;
    }

    isCancellable(registrationRequest) {
        if (!!registrationRequest.registration.id && registrationRequest.status === Status.REGISTERED) {
            return registrationRequest.registration.cancelable;
        }
        //Non-registered statuses can't cancel
        else {
            return false;
        }
    }

    async cancelModal(cancelRequest) {
        //Check
        const cancelCheck = await this.$bvModal.msgBoxConfirm(sprintf(
            'Are you sure you wish to cancel the registration for user %s for session %s? ' +
            'Any related travel requests will be canceled as well.',
            cancelRequest.user.fullname, cancelRequest.session.label), {
            title: 'Confirm Registration Cancellation',
            noCloseOnBackdrop: true,
            noCloseOnEsc: true,
            headerBgVariant: 'dark',
            headerTextVariant: 'white'
        });
        if (!cancelCheck) {
            // console.log('Cancellation aborted!');
            return;
        }
        // console.log('Cancellation approved');
        // console.log(cancelRequest);
        try {
            await this.$store.dispatch('instructor/cancelRegistration', cancelRequest, {root: true});
        }
        catch (error) {
            this.$bvToast.toast(error.message, errorToastOptions);
        }
        await this.addRegistrationAction();
    }

    async addRegistrationAction() {
        this.refreshSchedule();
    }
}
</script>
<style scoped>
    a.session {
        color: white;
    }
</style>