<template>
    <div>Mentorship Activity Snippet</div>
</template>
<script>

export default {
    name: 'MASnippet'
};


</script>
<style scoped>

</style>
