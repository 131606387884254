<template>
    <b-row>
        <b-col>
            <administrator-schedule :adminId="selectedUser.id" v-if="selectedUser.isAnAdministrator()"></administrator-schedule>
            <instructor-schedule :instructorId="selectedUser.id" v-if="selectedUser.isAnInstructor()"></instructor-schedule>
            <mentor-schedule :mentorId="selectedUser.id" v-if="selectedUser.isAMentor()"></mentor-schedule>
            <trainee-schedule :traineeId="selectedUser.id" v-if="selectedUser.isATrainee()"></trainee-schedule>
        </b-col>
    </b-row>
</template>
<script>
import { Vue, Component, Prop } from 'vue-property-decorator';
import store from '@/store/store';
import Breadcrumb from '@/views/menu/breadcrumb/breadcrumb';
import {NULL_USER} from '@/model/user';
import userDao from '@/dao/user_dao';
import _ from 'underscore';
import AdministratorSchedule from '@/views/private/schedule/AdministratorSchedule.vue';
import InstructorSchedule from '@/views/private/schedule/InstructorSchedule.vue';
import MentorSchedule from '@/views/private/schedule/MentorSchedule.vue';
import TraineeSchedule from '@/views/private/schedule/TraineeSchedule.vue';
import {errorToastOptions} from "../../../util/formatters";

const breadcrumbs = [
    Breadcrumb.create('Administration', {name: 'adminMain'}, false),
    Breadcrumb.create('Schedule', {name: 'schedule'}, false)
];

async function routeHandler(to, from, next) {
    _.each([
        'tracks/loadPrograms',
        'registration/loadRegistrableUserIds',
        'registration/loadCancelableUserIds'
    ], (action) => {
        store.dispatch(action)
            .catch(error => {
                const vm = new Vue();
                vm.$bvToast.toast(error.message, errorToastOptions);
            });
    });
    store.commit('setBreadcrumbs', breadcrumbs);
    const currentUser = store.getters['userSession/getUser'];

    if (!currentUser) {
        return next(false);
    }

    const userId = parseInt(to.params.userId, 10);

    if (isNaN(userId)) {
        store.commit('users/setUser', NULL_USER);
    }
    else {
        try {
            const selectedUser = await userDao.getUserById(userId);
            store.commit('users/setUser', selectedUser);
        }
        catch (error) {
            const vm = new Vue();
            vm.$bvToast.toast(error.message, errorToastOptions);
        }
    }
    // if (!user.isAMentor() && isNaN(traineeId)) {
    //     return next('/admin');
    // }
    //
    // if (user.isATrainee() && user.id !== traineeId) {
    //     return next('/admin');
    // }
    //
    // if (user.isAMentor() && isNaN(traineeId)) {
    //     const trainees = await userDao.getTraineesForMentor(user.id);
    //
    //     const trackIds = _.chain(trainees).map((t) => t.trackId).uniq().value();
    //
    //     _.each(trackIds, async (id) => {
    //         const track = await trackDao.getTrackById(id);
    //         store.commit('tracks/addTrack', track);
    //     });
    //
    //     store.commit('trainees/setTrainees', trainees);
    //
    // } else {
    //     const trainee = await userDao.getUserById(traineeId);
    //     store.commit('trainees/setTrainee', trainee);
    //
    //     const track = await trackDao.getTrackById(trainee.trackId);
    //     store.commit('tracks/addTrack', track);
    // }
    next();
}



@Component({
    components: {
        AdministratorSchedule,
        InstructorSchedule,
        MentorSchedule,
        TraineeSchedule
    }
})
export default class SchedulePage extends Vue {
    @Prop({type: [Number, String]}) userId;

    mounted() {
        // console.log(this.selectedUser);
    }

    get currentUser() {
        return this.$store.getters['userSession/getUser'];
    }

    get selectedUser() {
        return this.$store.getters['users/user'];
    }

    async beforeRouteUpdate(to, from, next) {
        routeHandler(to, from, next);
    }

    async beforeRouteEnter(to, from, next) {
        routeHandler(to, from, next);
    }
}
</script>
<style scoped>

</style>


