<template>
    <div>Admin schedule for {{ adminId }}</div>
</template>
<script>

import { Vue, Component, Prop } from 'vue-property-decorator';

@Component
export default class AdministratorSchedule extends Vue {

    @Prop({type: [Number, String]})
    adminId;
}
</script>
<style scoped>

</style>