<template>
    <datepicker :lang="{}"
                :state="state"
                :type="type"
                :value="value"
                @input="$emit('input', $event)"
                :width="width"
                :format="format"
                :clearable="clearable"
                :range="range"
                :range-separator="rangeSeparator"
                :confirm="confirm"
                :disabled="disabled"
                :editable="editable"
                :placeholder="placeholder"
                :input-class="inputClass"
                :time-picker-options="{ start: '00:00', step: '01:00', end: '23:00' }"
                :append-to-body="appendToBody">
        <template v-slot:calendar-icon v-if="!showIcon"><span></span></template>
    </datepicker>
</template>
<script>

import {Vue, Component, Prop} from 'vue-property-decorator';
import Datepicker from 'vue2-datepicker';
import {sprintf} from 'sprintf-js';

@Component({
    components: {
        Datepicker
    }
})
export default class CITFDatePicker extends Vue {
    @Prop({type: Boolean, default: true}) state;
    @Prop({type: [Date, Array]}) value;
    @Prop({type: String}) type;
    @Prop({type: Boolean, default: false}) hideIcon;
    @Prop({type: Number, default: 210}) width; // This width comes from the default from date-picker
    @Prop({type: String, default: 'YYYY-MM-DD'}) format;
    @Prop({type: Boolean, default: true}) clearable;
    @Prop({type: Boolean, default: false}) range;
    @Prop({type: String, default: ' → '}) rangeSeparator;
    @Prop({type: Boolean, default: false}) confirm;
    @Prop({type: Boolean, default: true}) editable;
    @Prop({type: Boolean, default: false}) disabled;
    @Prop({type: String, default: 'Select Date'}) placeholder;
    @Prop({type: Boolean, default: true}) appendToBody;
    @Prop({type: Boolean, default: false}) showState;

    get showIcon() {
        return !this.hideIcon && !this.showState;
    }

    get inputClass() {
        return sprintf('form-control form-control-sm%s',
            !this.showState ? '' : ' ' + (this.state ? 'is-valid' : 'is-invalid')
        );
    }
}
</script>
<style scoped xml:lang="scss">
    @import '~vue2-datepicker/index.css';
</style>