<template>
    <div>
        <b-row>
            <b-col>
                <template v-if="isRegistrableUser">
                    <b-button variant="primary" size="sm"
                              @click="registration">Register</b-button>
                    <br /><br />
                </template>
                <h4>
                    {{selectedUser.firstName + ' ' + selectedUser.lastName}} is a Mentor for<template v-if="!multipleTrainees">:</template>
                    <template v-else> these Trainees:</template>
                </h4>
            </b-col>
        </b-row>
        <b-row v-if="showTraineeSelector">
            <b-col>
                <b-select size="sm" v-model="selectedTrainee" :options="availableTrainees">
                    <option slot="first" :value="nullUser" :disabled="true"> - Select a Trainee - </option>
                </b-select>
            </b-col>
        </b-row>
        <b-row v-if="!nullUser.equals(selectedTrainee)">
            <b-col>
            <b-row>
                <b-col>
                    <div class="card card-body bg-light">
                        <h4>
                            {{selectedTrainee.firstName + ' ' + selectedTrainee.lastName}} - Track {{ selectedTraineeTrack.title }}
                        </h4>
                        <b-link :to="{name:'schedule', params: {userId: selectedTrainee.id}}">View Trainee Schedule</b-link>
                    </div>
                </b-col>
            </b-row>
            <b-row>
                <b-col>
                    <b-table-lite bordered hover striped small
                                  table-variant="primary"
                                  stacked="md"
                                  head-variant="dark"
                                  :fields="programFields"
                                  :items="programSchedule">
                        <template v-slot:cell(name)="row">
                            <b-link
                                    class="session"
                                    @click="getDetails(row.item.session)">{{row.item.session.programLabel}}</b-link>
                        </template>
                        <template v-slot:cell(description)="row">{{getProgramDescription(row.item.session)}}</template>
                        <template v-slot:cell(session)="row">
                            <span v-html="formatSessionDates(row.item.session)"></span>
                        </template>
                        <template v-slot:cell(status)="row">
                            <!--It takes too long for the store calls to return for UI rendering registration option verification-->
                            <!--These steps are delegated to the called methods-->
                            <!--Additionally, mentor registration status doesn't include incomplete-->
                            <b-dropdown
                                    :variant="statusVariant(row.item.status)"
                                    :text="row.item.status"
                                    size="sm">
                                <template v-if="isRegistered(row.item.status)">
                                    <b-dropdown-item
                                            href="#"
                                            @click="cancel(row.item)">Cancel registration</b-dropdown-item>
                                </template>
                                <template v-else-if="(isScheduled(row.item.status) || (isCancelled(row.item.status) && !row.item.session.canceled)) && isRegistrableUser">
                                    <b-dropdown-item
                                            href="#"
                                            @click="register(row.item)">Register</b-dropdown-item>
                                </template>
                                <template v-else>
                                    <b-dropdown-item>No actions available</b-dropdown-item>
                                </template>
                            </b-dropdown>
                        </template>
                    </b-table-lite>
                </b-col>
            </b-row>
            </b-col>
        </b-row>
        <!-- SESSION DETAILS MODAL -->
        <b-modal :id="sessionDetailsModalId"
                 size="xl"
                 scrollable
                 ok-only
                 title="Session Details"
                 header-bg-variant="dark"
                 header-text-variant="white">
            <SessionDetails
                    :session="session" />
        </b-modal>
        <!--REGISTRATION MODAL-->
        <b-modal :id="registrationModalId"
                 size="xl"
                 scrollable
                 title="Registration"
                 header-bg-variant="dark"
                 header-text-variant="white">
            <UserRegistration
                    :user="selectedUser"
                    @done="closeRegistration" />
        </b-modal>
        <!-- CONFIRM REGISTRATION MODAL -->
        <b-modal :id="confirmRegistrationModalId"
                 size="xl"
                 scrollable
                 noCloseOnBackdrop
                 noCloseOnEsc
                 hideHeaderClose
                 hideFooter
                 title="Confirm Registration"
                 header-bg-variant="dark"
                 header-text-variant="white">
            <ConfirmRegistration
                    :registrationRequest="registrationRequest"
                    :requestType="requestType"
                    @registered="registered"
                    @canceled="canceled" />
        </b-modal>
    </div>
</template>
<script>

import { Vue, Component, Prop } from 'vue-property-decorator';
import userDao from '@/dao/user_dao';
import {NULL_USER} from '@/model/user';
import _ from 'underscore';
import formatters from '@/util/formatters';
import UserRegistration from '@/views/private/users/UserRegistration.vue';
import ConfirmRegistration from "@/views/private/tracks/ConfirmRegistration.vue";
import {Status, RegistrationRequestType} from '@/model/registration';
import store from '@/store/store';
import {sprintf} from "sprintf-js";
import User from "../../../model/user";
import {Track as NULL_TRACK} from "../../../model/track";
import SessionDetails from '@/views/private/tracks/SessionDetails.vue';
import {errorModalOptions, errorToastOptions} from "../../../util/formatters";
import covidRegistrationRestriction from '@/errors/covid_registration_restriction';

@Component({
    components: {
        UserRegistration,
        ConfirmRegistration,
        SessionDetails
    },
    asyncComputed: {
        isRegistrableUser: {
            async get() {
                if (!this.mentorId) {
                    return false;
                }
                try {
                    await store.dispatch('registration/loadRegistrableUserIds');
                }
                catch (error) {
                    this.$bvToast.toast(error.message, errorToastOptions);
                }
                return store.getters['registration/isRegistrableUserId'](this.mentorId);
            }
        },
        isCancelableUser: {
            async get() {
                if (!this.mentorId) {
                    return false;
                }
                try {
                    await store.dispatch('registration/loadCancelableUserIds');
                }
                catch (error) {
                    this.$bvToast.toast(error.message, errorToastOptions);
                }
                return store.getters['registration/isCancelableUserId'](this.mentorId);
            }
        },
        programSchedule: {
            async get() {
                if (!this.mentorId || !User.isValidUser(this.selectedUser) || !User.isValidUser(this.selectedTrainee)) {
                    return [];
                }
                try {
                    await store.dispatch('registration/loadMentorScheduleForTrainee', {
                        mentor: this.selectedUser,
                        trainee: this.selectedTrainee
                    });
                }
                catch (error) {
                    this.$bvToast.toast(error.message, errorToastOptions);
                }
                const result = store.getters['registration/mentorScheduleForTrainee']({
                    mentorId: this.selectedUser.id,
                    traineeId: this.selectedTrainee.id
                });
                return result;
            },
            watch: ['selectedTrainee']
        }
    },
    methods: {
        refreshSchedule() {
            this.updateUser();
            this.$asyncComputed.programSchedule.update();
        }
    }
})

export default class MentorSchedule extends Vue {
    @Prop({type: [Number, String]}) mentorId;
    nullUser = NULL_USER.clone();
    registrationRequest = null;
    session = null;

    get sessionDetailsModalId() {
        if (_.isNull(this.session)) {
            return 'session-details-modal-invalid';
        }
        else {
            return sprintf('session-details-modal-%d', this.session.sessionId);
        }
    }

    getDetails(session) {
        this.session = session;
        this.$bvModal.show(this.sessionDetailsModalId);
    }

    registered() {
        this.refreshSchedule();
        this.$bvModal.hide(this.confirmRegistrationModalId);
        this.registrationRequest = null;
    }

    canceled() {
        this.$bvModal.hide(this.confirmRegistrationModalId);
        this.registrationRequest = null;
    }

    get confirmRegistrationModalId() {
        return sprintf('confirm-registration-modal-%d', this.mentorId);
    }

    get requestType() {
        return RegistrationRequestType.SESSION_REGISTRATION;
    }

    isRegistered(status) {
        return _.isEqual(status, Status.REGISTERED);
    }

    isScheduled(status) {
        return _.isEqual(status, Status.SCHEDULED);
    }

    isCancelled(status) {
        return _.isEqual(status, Status.CANCELED);
    }

    get registrationModalId() {
        if (!this.mentorId) {
            return 'mentor-registration-modal-invalid';
        }
        else {
            return 'mentor-registration-modal-' + this.mentorId;
        }
    }

    registration() {
        this.$bvModal.show(this.registrationModalId);
    }

    async closeRegistration() {
        this.$bvModal.hide(this.registrationModalId);
        this.refreshSchedule();
    }

    async cancel(scheduleItem) {
        if (!this.isCancelableUser || !this.isCancellable(scheduleItem)) {
            await this.$bvModal.msgBoxOk('Sorry, user is not eligible to cancel registration for this session.');
        }
        else {
            const cancelCheck = await this.$bvModal.msgBoxConfirm(sprintf(
                'Are you sure you wish to cancel the registration for user %s for session %s? ' +
                'Any related travel requests will be canceled as well.',
                scheduleItem.user.fullname, scheduleItem.session.label), {
                title: 'Confirm Registration Cancellation',
                noCloseOnBackdrop: true,
                noCloseOnEsc: true,
                headerBgVariant: 'dark',
                headerTextVariant: 'white'
            });
            if (!cancelCheck) {
                return;
            }
            scheduleItem.registration.canceled = true;
            try {
                await this.$store.dispatch('registration/cancelRegistration', scheduleItem, {root: true});
                this.refreshSchedule();
            }
            catch (error) {
                await this.$bvModal.msgBoxOk(error.message, errorModalOptions);
                scheduleItem.registration.canceled = false;
            }
        }
    }

    async register(scheduleItem) {
        if (!this.isRegistrableUser || !(await this.isRegistrable(scheduleItem))) {
            await this.$bvModal.msgBoxOk('Sorry, user is not eligible to register for this session.');
        }
        else {
            //Check for override
            if (scheduleItem.needsOverride) {
                const activeUser = store.getters['userSession/getUser'];
                if (activeUser.isAnAdministrator()) {
                    const check = await this.$bvModal.msgBoxConfirm('The participant you are attempting to ' +
                        'register for a session that requires vaccine verification has not been verified. Would you ' +
                        'like to override the vaccine requirement and register this participant anyway?', {
                        title: 'Vaccine Override Needed',
                        noCloseOnBackdrop: true,
                        noCloseOnEsc: true,
                        headerBgVariant: 'dark',
                        headerTextVariant: 'white'
                    });
                    if (check) {
                        //Set override flag
                        scheduleItem.registration.vaccineOverride = true;
                    } else {
                        return;
                    }
                } else {
                    //A non-admin cannot override
                    return this.$bvModal.msgBoxOk(covidRegistrationRestriction(this), {
                        size: 'md',
                        buttonSize: 'sm'
                    });
                }
            }
            this.registrationRequest = scheduleItem;
            this.registrationRequest.registration.canceled = false;
            this.$bvModal.show(this.confirmRegistrationModalId);
        }
    }

    isCancellable(registrationRequest) {
        if (!!registrationRequest.registration.id && registrationRequest.status === Status.REGISTERED) {
            return registrationRequest.registration.cancelable;
        }
        //Non-registered statuses can't cancel
        else {
            return false;
        }
    }

    async isRegistrable(registrationRequest) {
        if (_.isEqual(registrationRequest.status, Status.SCHEDULED) ||
            _.isEqual(registrationRequest.status, Status.CANCELED)) {
            try {
                await this.$store.dispatch('registration/loadRegistrableSessionsByUser', registrationRequest.user);
                const registrableSessionsForUser = this.$store.getters['registration/registrableSessionsByUser'](registrationRequest.user);
                const registrable = _.find(registrableSessionsForUser, session => {
                    return session.sessionId === registrationRequest.session.sessionId;
                });
                return _.isUndefined(registrable) ? false : true;
            }
            catch (error) {
                this.$bvToast.toast(error.message, errorToastOptions);
            }
        }
        //Non-eligible/non-scheduled statuses can't register
        else {
            return false;
        }
    }

    get availableTrainees() {
        return _.map(this.trainees, (t) => {
            return {
                text: t.fullname,
                value: t
            };
        });
    }

    get showTraineeSelector() {
        // Only show the trainee selector if the selected mentor has more than one trainee
        return this.multipleTrainees;
    }

    get selectedTraineeTrack() {
        return (NULL_USER.equals(this.selectedTrainee) || !this.selectedTrainee.trackId) ?
            NULL_TRACK.clone() : this.$store.getters['tracks/getTrackById'](this.selectedTrainee.trackId);
    }

    get programFields() {
        return [{
            key: 'name',
            label: 'Program'
        }, {
            key: 'description',
            label: 'Description'
        }, {
            key: 'session',
            label: 'Session'
        }, {
            key: 'status',
            label: 'Status'
        }];
    }

    getProgramDescription(session) {
        const programs = this.$store.getters['tracks/getPrograms'];
        const program = _.find(programs, p => {
            return p.idx === session.idx;
        });
        return program.description;
    }

    get multipleTrainees() {
        return 1 < this.availableTrainees.length;
    }

    formatSessionDates(session) {
        const dates = session.dates;
        return null === dates ? '' : sprintf('%s &rarr; %s',
            formatters.date(dates[0], 'M/d/yyyy'),
            formatters.date(dates[1], 'M/d/yyyy')
        );
    }

    statusVariant(status) {
        return Status.variant(status);
    }

    get selectedUser() {
        return this.$store.getters['users/user'];
    }

    get selectedTrainee() {
        return this.$store.getters['trainees/trainee'];
    }

    set selectedTrainee(trainee) {
        this.$store.commit('trainees/setTrainee', trainee);
    }

    get trainees() {
        const trainees = this.$store.getters['trainees/trainees'] || [];
        return trainees;
    }

    async updateUser() {
        if (!this.mentorId) {
            return;
        }
        //Single call to user store with fetch and commit mentor (user)
        try {
            await store.dispatch('users/loadUser', this.mentorId);
        }
        catch (error) {
            this.$bvToast.toast(error.message, errorToastOptions);
        }
    }

    async updateTrainees() {
        if (!this.mentorId) {
            return;
        }
        const traineeIds = this.selectedUser.traineeIds;
        try {
            const trainees = await userDao.getUsersByIds(traineeIds);
            store.commit('trainees/setTrainees', trainees);
            store.commit('trainees/setTrainee', 1 === trainees.length ? trainees[0] : NULL_USER);
        }
        catch (error) {
            this.$bvToast.toast(error.message, errorToastOptions);
        }
    }

    async mounted() {
        try {
            await store.dispatch('tracks/loadTracks');
        }
        catch (error) {
            this.$bvToast.toast(error.message, errorToastOptions);
        }
        await this.updateUser();
        await this.updateTrainees();
    }
}
</script>
<style scoped>
    a.register-btn {
        color: #FFF;
    }
    a.register-btn:hover {
        color: #FF0 !important;
    }
    a.session {
        color: white;
    }
</style>