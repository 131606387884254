<template>
    <div>
        <!-- HEADER -->
        <b-row>
            <b-col>
                <div  class="alert alert-secondary">
                    <h4 class="alert-heading">
                        Schedule for {{selectedTrainee.fullname}} - Track {{ selectedTraineeTrack | trackTitle }}
                    </h4>
                    <hr/>
                    <p>
                        <!-- Counts based on asyncComputed not determined in time for UI mounting -->
                        Assigned OJTs: {{scheduledOJTs}} ({{completedOJTs}} completed)
                        Assigned MAs: {{scheduledMAs}} ({{completedMAs}} completed)
                    </p>
<!--                    <b-alert variant="dark" show v-if="isBehind">-->
<!--                        Behind schedule-->
<!--                    </b-alert>-->
                    <b-button-group>
                        <b-button variant="success"
                                  size="sm"
                                  v-if="userIsAssignable && !isTraineeUser"
                                  @click="assignment">Assign Activities</b-button>
                        <b-button variant="warning"
                                  size="sm"
                                  v-if="userIsAssignable && !isTraineeUser"
                                  @click="createCustomActivity">Create Custom Activity</b-button>
                        <b-button variant="secondary"
                                  size="sm"
                                  v-if="userIsAssignable && !isTraineeUser"
                                  @click="copyTraineeSchedule">Copy Activity Schedule</b-button>
                        <b-button variant="primary"
                                  size="sm"
                                  v-if="isRegistrableTrainee && !isTraineeUser"
                                  @click="registration">Register</b-button>
                        <b-button variant="primary"
                                  size="sm"
                                  v-else
                                  @click="showRules">Why Can't I Register?</b-button>
                        <b-button variant="dark"
                                  size="sm"
                                  @click="getTraineePlanningCalendar">Trainee Planning Calendar</b-button>
                    </b-button-group>
                </div>
            </b-col>
        </b-row>
        <!-- PROGRAM SESSION SCHEDULE -->
        <b-row>
            <b-col>
                <b-table-lite hover striped small
                              stacked="sm"
                              head-variant="dark"
                              table-variant="primary"
                              :fields="programFields"
                              :items="programSchedule">
                    <template v-slot:cell(name)="row">
                        <b-link
                                class="session"
                                @click="getDetails(row.item.session)">{{row.item.session.programLabel}}</b-link>
                    </template>
                    <template v-slot:cell(description)="row">{{getProgramDescription(row.item.session)}}</template>
                    <template v-slot:cell(session)="row">
                        <span v-html="formatSessionDates(row.item.session)"></span>
                    </template>
                    <template v-slot:cell(status)="row">
                        <!--It takes too long for the store calls to return for UI rendering registration option verification-->
                        <!--These steps are delegated to the called methods-->
                        <b-dropdown
                                :variant="statusVariant(row.item.status)"
                                :text="row.item.status"
                                size="sm">
                            <template v-if="isRegistered(row.item.status) && !!row.item.hasRegistrationId && !isTraineeUser">
                                <b-dropdown-item
                                        href="#"
                                        @click="cancel(row.item)">Cancel registration</b-dropdown-item>
                                <b-dropdown-item
                                        href="#"
                                        v-if="allowCompleteIncomplete"
                                        @click="incomplete(row.item)">Mark incomplete</b-dropdown-item>
                            </template>
                            <template v-else-if="((isScheduled(row.item.status) || (isCancelled(row.item.status) && !row.item.session.canceled))) && isRegistrableTrainee && !isTraineeUser">
                                <b-dropdown-item
                                        href="#"
                                        @click="register(row.item)">Register</b-dropdown-item>
                            </template>
                            <template v-else-if="isCompleted(row.item.status) && !!row.item.hasRegistrationId && allowCompleteIncomplete && !isTraineeUser">
                                <b-dropdown-item
                                        href="#"
                                        @click="incomplete(row.item)">Mark incomplete</b-dropdown-item>
                            </template>
                            <template v-else-if="isIncomplete(row.item.status) && !!row.item.hasRegistrationId && allowCompleteIncomplete && !isTraineeUser">
                                <b-dropdown-item
                                        href="#"
                                        @click="complete(row.item)">Mark complete</b-dropdown-item>
                            </template>
                            <template v-else>
                                <b-dropdown-item>No actions available</b-dropdown-item>
                            </template>
                        </b-dropdown>
                    </template>
                </b-table-lite>
            </b-col>
        </b-row>
        <!-- ACTIVITY SCHEDULE -->
        <b-row>
            <b-col>
                <b-table hover striped small outlined fixed
                         stacked="md"
                         head-variant="dark"
                         :fields="activityFields"
                         :items="activitySchedule">
                    <template v-slot:thead-top="data">
                        <tr>
                            <th>
                                <b-input
                                        v-model="activityFilter"
                                        placeholder="Activity"
                                        size="sm"
                                        @input="refreshActivitySchedule" />
                            </th>
                            <th>
                                <b-input
                                        v-model="titleFilter"
                                        placeholder="Title"
                                        size="sm"
                                        @input="refreshActivitySchedule" />
                            </th>
                            <th>
                                <b-input
                                        v-model="descriptionFilter"
                                        placeholder="Description"
                                        size="sm"
                                        @input="refreshActivitySchedule" />
                            </th>
                            <th>
                                <CITFDatePicker
                                        v-model="toBeCompletedFilter"
                                        :width="250" confirm range
                                        format="M/D/YYYY"
                                        @change="refreshActivitySchedule" />
                            </th>
                            <th>
                                <CITFDatePicker
                                        v-model="completedFilter"
                                        :width="250" confirm range
                                        format="M/D/YYYY"
                                        @change="refreshActivitySchedule" />
                            </th>
                            <th colspan="2"></th>
                        </tr>
                    </template>
                    <template v-slot:cell(displayId)="row">
                        <template
                                v-if="row.item.isCustomActivity()">{{row.item.workspace.customDisplayId}}</template>
                        <template
                                v-else>{{row.item.workspace.displayId}}</template>
                        <template
                                v-if="row.item.isCustomActivity() && !row.item.isApproved">
                            <br />
                            <b-badge
                                    variant="warning">{{row.item.workspace.status}}</b-badge>
                        </template>
                    </template>
                    <template v-slot:cell(scheduledDate)="row">
                        <template
                                v-if="row.item.editMode">
                            <CITFDatePicker v-model="row.item.workspace.scheduled"
                                            :width="100"
                                            type="month"
                                            format="MMM YYYY" />
                        </template>
                        <template v-else>
                            {{row.item.scheduled | dateFormatter('MMM yyyy')}}
                            <template v-if="row.item.isLate">
                                (past recommended date)
                            </template>
                        </template>
                    </template>
                    <template v-slot:cell(completed)="row">
                        <template v-if="row.item.editMode">
                            <CITFDatePicker v-model="row.item.workspace.completed"
                                            :width="100"
                                            format="M/D/YYYY" />
                        </template>
                        <template v-else>
                            {{ row.item.completed | dateFormatter('M/d/yyyy') }}
                        </template>
                    </template>
                    <template v-slot:cell(buttons)="row">
                        <template v-if="row.item.editMode">
                            <b-button-group size="sm">
                                <b-button
                                        variant="success"
                                        @click="saveActivity(row.item)">Save Changes</b-button>
                                <b-button
                                        variant="primary"
                                        @click="cancelSaveActivity(row.item)">Cancel Edit</b-button>
                                <b-button
                                        variant="danger"
                                        @click="cancelActivityAssignment(row.item)">Delete Assignment</b-button>
                            </b-button-group>
                        </template>
                        <template v-else>
                            <b-link
                                    v-if="userIsAssignable"
                                    @click="row.item.editMode = true">edit</b-link>
                        </template>
                    </template>
                    <template v-slot:cell(documents)="row">
                        <template v-if="row.item.isOJT()">
                            <b-link
                                    v-if="!!row.item.url"
                                    :href="row.item.url"
                                    target="_blank">more info</b-link>
                        </template>
                        <template v-if="row.item.isMA()">
                            <b-link
                                    v-if="!!row.item.suggestions"
                                    @click="downloadSuggestions(row.item.activityId)">suggestions</b-link>
                            &nbsp;
                            <b-link
                                    v-if="!!row.item.reference"
                                    @click="downloadReference(row.item.activityId)">reference</b-link>
                        </template>
                    </template>
                </b-table>
            </b-col>
        </b-row>
        <!--RULES MODAL-->
        <b-modal :id="rulesModalId"
                 size="xl"
                 scrollable
                 ok-only
                 button-size="sm"
                 title="Registration Rules"
                 header-bg-variant="dark"
                 header-text-variant="white">
            <registration-rules />
        </b-modal>
        <!-- SESSION DETAILS MODAL -->
        <b-modal :id="sessionDetailsModalId"
                 size="xl"
                 scrollable
                 ok-only
                 title="Session Details"
                 header-bg-variant="dark"
                 header-text-variant="white">
            <SessionDetails
                    :session="session" />
        </b-modal>
        <!--REGISTRATION MODAL-->
        <b-modal :id="registrationModalId"
                 size="xl"
                 scrollable
                 title="Registration"
                 header-bg-variant="dark"
                 header-text-variant="white">
            <UserRegistration
                    :user="selectedTrainee"
                    @done="closeRegistration" />
        </b-modal>
        <!-- CONFIRM REGISTRATION MODAL -->
        <b-modal :id="confirmRegistrationModalId"
                 size="xl"
                 scrollable
                 noCloseOnBackdrop
                 noCloseOnEsc
                 hideHeaderClose
                 hideFooter
                 title="Confirm Registration"
                 header-bg-variant="dark"
                 header-text-variant="white">
            <ConfirmRegistration
                    :registrationRequest="registrationRequest"
                    :requestType="requestType"
                    @registered="registered"
                    @canceled="canceled" />
        </b-modal>
        <!-- ASSIGNMENT MODAL -->
        <b-modal :id="assignmentModalId"
                 size="xl"
                 scrollable
                 ok-only
                 title="Assign Activities"
                 header-bg-variant="dark"
                 header-text-variant="white">
            <ActivityAssignment
                    :trainee="selectedTrainee"
                    @assignment="refreshActivitySchedule"/>
        </b-modal>
        <!-- CREATE CUSTOM ACTIVITY MODAL -->
        <b-modal :id="createCustomModalId"
                 size="xl"
                 scrollable
                 hide-footer
                 title="Create New Custom Activity"
                 header-bg-variant="dark"
                 header-text-variant="white">
            <CreateCustomActivity
                    :trainee="selectedTrainee"
                    @done="closeCreateCustomActivity" />
        </b-modal>
        <!-- COPY TRAINEE SCHEDULE MODAL -->
        <b-modal :id="copyScheduleModalId"
                 size="xl"
                 scrollable
                 hide-footer
                 title="Copy Trainee Activity Schedule"
                 header-bg-variant="dark"
                 header-text-variant="white">
            <CopyTraineeSchedule
                    :trainee="selectedTrainee"
                    @done="closeCopyTraineeSchedule" />
        </b-modal>
    </div>
</template>
<script>

import { Vue, Component, Prop } from 'vue-property-decorator';
import userDao from '@/dao/user_dao';
import trackDao from '@/dao/track_dao';
import {NULL_USER} from '@/model/user';
import {NULL_TRACK, Track} from '@/model/track';
import {Status, RegistrationRequestType} from '@/model/registration';
import {CustomActivityStatus, OJT, MA} from '@/model/activity';
import UserRegistration from '@/views/private/users/UserRegistration.vue';
import ConfirmRegistration from "@/views/private/tracks/ConfirmRegistration.vue";
import {addDays, subDays, isAfter, isBefore, endOfMonth, set} from 'date-fns';
import _ from 'underscore';
import formatters from '@/util/formatters';
import CITFDatePicker from '@/components/shared/CITFDatePicker.vue';
import OJTSnippet from '@/views/private/schedule/OJTSnippet.vue';
import ActivityAssignment from '@/views/private/schedule/ActivityAssignment.vue';
import CopyTraineeSchedule from '@/views/private/schedule/CopyTraineeSchedule.vue';
import CreateCustomActivity from '@/views/private/activities/custom/CreateCustomActivity.vue';
import MASnippet from '@/views/private/schedule/MASnippet.vue';
import {isValid as isValidDate} from 'date-fns';
import download from '@/util/downloader';
import {sprintf} from "sprintf-js";
import store from '@/store/store';
import activityDao from "../../../dao/activity_dao";
import User from "../../../model/user";
import SessionDetails from '@/views/private/tracks/SessionDetails.vue';
import RegistrationRules from "../users/RegistrationRules";
import {errorModalOptions, errorToastOptions} from "../../../util/formatters";
import covidRegistrationRestriction from '@/errors/covid_registration_restriction';

@Component({
    filters: {
        dateFormatter: formatters.date,
        trackTitle: (track) => {
            return !(track instanceof Track) || NULL_TRACK.equals(track) ? '' : track.title;
        }
    },
    components: {
        RegistrationRules,
        CITFDatePicker,
        OJTSnippet,
        MASnippet,
        UserRegistration,
        ConfirmRegistration,
        ActivityAssignment,
        CreateCustomActivity,
        CopyTraineeSchedule,
        SessionDetails
    },
    asyncComputed: {
        programSchedule: {
            async get() {
                if (!this.traineeId || !User.isValidUser(this.selectedTrainee)) {
                    return [];
                }
                //Trainee updated by refresh call to prevent infinite looping - update schedule only
                try {
                    await store.dispatch('registration/loadTraineeSchedule', this.selectedTrainee);
                }
                catch (error) {
                    this.$bvToast.toast(error.message, errorToastOptions);
                }
                const schedule = store.getters['registration/traineeSchedule'](this.traineeId);
                if (!this.selectedTrainee.isEnrolled) {
                    //Don't show scheduled sessions for dropped trainees
                    const filteredSchedule = _.filter(schedule, item => {
                        return !this.isScheduled(item.status);
                    });
                    return filteredSchedule;
                }
                else {
                    return schedule;
                }
            }
        },
        isRegistrableTrainee: {
            async get() {
                if (!this.traineeId) {
                    return false;
                }
                try {
                    await store.dispatch('registration/loadRegistrableUserIds');
                }
                catch (error) {
                    this.$bvToast.toast(error.message, errorToastOptions);
                }
                return store.getters['registration/isRegistrableUserId'](this.traineeId);
            }
        },
        isCancelableTrainee: {
            async get() {
                if (!this.traineeId) {
                    return false;
                }
                try {
                    await store.dispatch('registration/loadCancelableUserIds');
                }
                catch (error) {
                    this.$bvToast.toast(error.message, errorToastOptions);
                }
                return store.getters['registration/isCancelableUserId'](this.traineeId);
            }
        },
        activitySchedule: {
            async get() {
                if (!this.traineeId || !User.isValidUser(this.selectedTrainee)) {
                    return [];
                }
                else {
                    try {
                        await store.dispatch('activities/loadTraineeActivitySchedule', this.selectedTrainee);
                    }
                    catch (error) {
                        this.$bvToast.toast(error.message, errorToastOptions);
                    }
                    const activities = store.getters['activities/traineeActivitySchedule'](this.selectedTrainee);
                    const filteredActivities = _.chain(activities)
                        .filter(item => {
                            //Don't include cancelled activities
                            return !item.isTraineeActivityCancelled;
                        })
                        .filter(item => {
                            //Don't include declined, invalid, or cancelled custom activities
                            return item.isCustomActivity() ?
                                !item.isDeclined && !item.isInvalid && !item.cancelled :
                                true;
                        })
                        .filter(item => {
                            const filter = _.isNull(this.activityFilter) ? null : new RegExp(this.activityFilter, 'i');
                            const displayId = item.isCustomActivity() ? item.customDisplayId : item.displayId;
                            return _.isNull(filter) || filter.test(displayId);
                        })
                        .filter(item => {
                            const filter = _.isNull(this.titleFilter) ? null : new RegExp(this.titleFilter, 'i');
                            return _.isNull(filter) || filter.test(item.title);
                        })
                        .filter(item => {
                            const filter = _.isNull(this.descriptionFilter) ? null : new RegExp(this.descriptionFilter, 'i');
                            return _.isNull(filter) || filter.test(item.description);
                        })
                        .filter(item => {
                            //Date validation completed during activity construction
                            if (_.isNull(item.scheduled) || _.isUndefined(item.scheduled)) {
                                return true;
                            }
                            if (_.isNull(this.toBeCompletedFilter) || _.isNull(this.toBeCompletedFilter[0]) ||
                                this.toBeCompletedFilter.length < 2) {
                                return true;
                            }
                            const startDate = this.toBeCompletedFilter[0];
                            const endDate = addDays(this.toBeCompletedFilter[1], 1);
                            return isAfter(item.scheduled, startDate) && isBefore(item.scheduled, endDate);
                        })
                        .filter(item => {
                            //Date validation completed during activity construction
                            if (_.isNull(item.completed) || _.isUndefined(item.completed)) {
                                return true;
                            }
                            if (_.isNull(this.completedFilter) || _.isNull(this.completedFilter[0]) ||
                                this.completedFilter.length < 2) {
                                return true;
                            }
                            const startDate = this.completedFilter[0];
                            const endDate = addDays(this.completedFilter[1], 1);
                            return isAfter(item.completed, startDate) && isBefore(item.completed, endDate);
                        })
                        .sortBy(item => {
                            return item.displayIdOrdinal;
                        })
                        .sortBy(item => {
                            return item.displayCategory;
                        })
                        .map(item => {
                            item._rowVariant = item.isLate ? 'info' : null;
                            return item;
                        })
                        .value();
                    return filteredActivities;
                }
            },
            watch: ['toBeCompletedFilter', 'completedFilter']
        },
        scheduledOJTs: {
            async get() {
                const ojts = _.filter(this.activitySchedule, activity => {
                    return activity.isOJT() || (activity.isCustomActivity() && activity.isCustomOJT());
                });
                return ojts.length;
            }
        },
        completedOJTs: {
            async get() {
                const completedOJTs = _.chain(this.activitySchedule)
                    .filter(activity => {
                        return activity.isOJT() || (activity.isCustomActivity() && activity.isCustomOJT());
                    })
                    .filter(activity => {
                        return activity.isCompleted;
                    })
                    .value();
                return completedOJTs.length;
            }
        },
        scheduledMAs: {
            async get() {
                const mas = _.filter(this.activitySchedule, activity => {
                    return activity.isMA() || (activity.isCustomActivity() && activity.isCustomMA());
                });
                return mas.length;
            }
        },
        completedMAs: {
            async get() {
                const completedMAs = _.chain(this.activitySchedule)
                    .filter(activity => {
                        return activity.isMA() || (activity.isCustomActivity() && activity.isCustomMA());
                    })
                    .filter(activity => {
                        return activity.isCompleted;
                    })
                    .value();
                return completedMAs.length;
            }
        },
        isBehind: {
            async get() {
                if (!User.isValidUser(this.selectedTrainee) ||
                    !_.isNull(this.selectedTrainee.graduated) || !_.isNull(this.selectedTrainee.dropped)) {
                    return false;
                }
                try {
                    await store.dispatch('trainees/isTraineeBehind', this.selectedTrainee.id);
                    return store.getters['trainees/isTraineeBehind'](this.selectedTrainee.id);
                }
                catch (error) {
                    this.$bvToast.toast(error.message, errorToastOptions);
                    return false;
                }
            }
        }
    },
    methods: {
        refreshProgramSchedule() {
            this.updateTrainee();
            this.$asyncComputed.programSchedule.update();
            this.$asyncComputed.isRegistrableTrainee.update();
            this.$asyncComputed.isCancelableTrainee.update();
            this.$asyncComputed.isBehind.update();
        },
        refreshActivitySchedule() {
            this.$asyncComputed.activitySchedule.update();
            this.$asyncComputed.isBehind.update();
        }
    }
})

export default class TraineeSchedule extends Vue {
    @Prop({type: [Number, String]}) traineeId;
    activityFilter = null;
    titleFilter = null;
    descriptionFilter = null;
    toBeCompletedFilter = [null, null];
    completedFilter = [null, null];
    registrationRequest = null;
    session = null;

    async getTraineePlanningCalendar() {
        await download('POST', '/api/v1/report/trainee-planning-calendar', JSON.stringify({
            userProfileId: this.traineeId
        }));
    }

    get isTraineeUser() {
        const activeUser = store.getters['userSession/getUser'];
        return activeUser.isATrainee();
    }

    get rulesModalId() {
        return sprintf('reg-rules-%s', !this.traineeId ? 'invalid' : this.traineeId);
    }

    showRules() {
        this.$bvModal.show(this.rulesModalId);
    }

    get sessionDetailsModalId() {
        if (_.isNull(this.session)) {
            return 'session-details-modal-invalid';
        }
        else {
            return sprintf('session-details-modal-%d', this.session.sessionId);
        }
    }

    getDetails(session) {
        this.session = session;
        this.$bvModal.show(this.sessionDetailsModalId);
    }

    async downloadSuggestions(activityId) {
        await activityDao.getDocument('suggestions', activityId);
    }

    async downloadReference(activityId) {
        await activityDao.getDocument('reference', activityId);
    }

    registered() {
        this.refreshProgramSchedule();
        this.$bvModal.hide(this.confirmRegistrationModalId);
        this.registrationRequest = null;
    }

    canceled() {
        this.$bvModal.hide(this.confirmRegistrationModalId);
        this.registrationRequest = null;
    }

    get requestType() {
        return RegistrationRequestType.SESSION_REGISTRATION;
    }

    get confirmRegistrationModalId() {
        return sprintf('confirm-registration-modal-%d', this.traineeId);
    }

    isRegistered(status) {
        return _.isEqual(status, Status.REGISTERED);
    }

    isScheduled(status) {
        return _.isEqual(status, Status.SCHEDULED);
    }

    isCancelled(status) {
        return _.isEqual(status, Status.CANCELED);
    }

    isCompleted(status) {
        return _.isEqual(status, Status.COMPLETED);
    }

    isIncomplete(status) {
        return _.isEqual(status, Status.INCOMPLETE);
    }

    //Don't offer the option to mark a session registration incomplete unless user is admin (we already know this is a trainee)
    get allowCompleteIncomplete() {
        return this.$store.getters['userSession/isAnAdmin'];
    }

    async incomplete(scheduleItem) {
        const check = await this.$bvModal.msgBoxConfirm(
            'Are you sure you wish to make this session registration incomplete?', {
                title: 'Confirm Incomplete',
                noCloseOnBackdrop: true,
                noCloseOnEsc: true,
                headerBgVariant: 'dark',
                headerTextVariant: 'white'
            });
        if (!check) {
            return;
        }
        //Update registration - note that only one of complete/incomplete should have a value
        //Consider implementing workspace
        const oldComplete = scheduleItem.registration.complete;
        const oldIncomplete = scheduleItem.registration.incomplete;
        scheduleItem.registration.complete = null;
        scheduleItem.registration.incomplete = new Date();
        try {
            await this.$store.dispatch('registration/processRegistration', scheduleItem, {root: true});
            this.refreshProgramSchedule();
        }
        catch (error) {
            await this.$bvModal.msgBoxOk(error.message, errorModalOptions);
            scheduleItem.registration.complete = oldComplete;
            scheduleItem.registration.incomplete = oldIncomplete;
        }
    }

    async complete(scheduleItem) {
        const check = await this.$bvModal.msgBoxConfirm(
            'Are you sure you wish to make this session registration completed?', {
                title: 'Confirm Completed',
                noCloseOnBackdrop: true,
                noCloseOnEsc: true,
                headerBgVariant: 'dark',
                headerTextVariant: 'white'
            });
        if (!check) {
            return;
        }
        //Update registration - note that only one of complete/incomplete should have a value
        //Consider implementing workspace
        const oldComplete = scheduleItem.registration.complete;
        const oldIncomplete = scheduleItem.registration.incomplete;
        scheduleItem.registration.complete = new Date();
        scheduleItem.registration.incomplete = null;
        try {
            await this.$store.dispatch('registration/processRegistration', scheduleItem, {root: true});
            this.refreshProgramSchedule();
        }
        catch (error) {
            await this.$bvModal.msgBoxOk(error.message, errorModalOptions);
            scheduleItem.registration.complete = oldComplete;
            scheduleItem.registration.incomplete = oldIncomplete;
        }
    }

    get registrationModalId() {
        if (!this.traineeId) {
            return 'trainee-registration-modal-invalid';
        }
        else {
            return 'trainee-registration-modal-' + this.traineeId;
        }
    }

    get assignmentModalId() {
        if (!this.traineeId) {
            return 'trainee-assignment-modal-invalid';
        }
        else {
            return 'trainee-assignment-modal-' + this.traineeId;
        }
    }

    get createCustomModalId() {
        if (!this.traineeId) {
            return 'trainee-create-custom-modal-invalid';
        }
        else {
            return 'trainee-create-custom-modal-' + this.traineeId;
        }
    }

    get copyScheduleModalId() {
        if (!this.traineeId) {
            return 'copy-schedule-modal-invalid';
        }
        else {
            return 'copy-schedule-modal-' + this.traineeId;
        }
    }

    get userIsAssignable() {
        if (!User.isValidUser(this.selectedTrainee)) {
            return false;
        }
        return this.selectedTrainee.isEnabled && this.selectedTrainee.isEnrolled;
    }

    registration() {
        this.$bvModal.show(this.registrationModalId);
    }

    async closeRegistration() {
        this.$bvModal.hide(this.registrationModalId);
        this.refreshProgramSchedule();
    }

    assignment() {
        this.$bvModal.show(this.assignmentModalId);
    }

    createCustomActivity() {
        this.$bvModal.show(this.createCustomModalId);
    }

    copyTraineeSchedule() {
        this.$bvModal.show(this.copyScheduleModalId);
    }

    async closeCreateCustomActivity() {
        this.$bvModal.hide(this.createCustomModalId);
        this.refreshActivitySchedule();
    }

    async closeCopyTraineeSchedule() {
        this.$bvModal.hide(this.copyScheduleModalId);
    }

    async cancel(scheduleItem) {
        if (!this.isCancelableTrainee || !this.isCancellable(scheduleItem)) {
            await this.$bvModal.msgBoxOk('Sorry, user is not eligible to cancel registration for this session.');
        }
        else {
            const check = await this.$bvModal.msgBoxConfirm(sprintf(
                'Are you sure you wish to cancel the registration for user %s for session %s? ' +
                'Any related travel requests will be canceled as well.',
                scheduleItem.user.fullname, scheduleItem.session.label), {
                title: 'Confirm Registration Cancellation',
                noCloseOnBackdrop: true,
                noCloseOnEsc: true,
                headerBgVariant: 'dark',
                headerTextVariant: 'white'
            });
            if (!check) {
                return;
            }
            scheduleItem.registration.canceled = true;
            try {
                await this.$store.dispatch('registration/cancelRegistration', scheduleItem, {root: true});
                this.refreshProgramSchedule();
            }
            catch (error) {
                await this.$bvModal.msgBoxOk(error.message, errorModalOptions);
                scheduleItem.registration.canceled = false;
            }
        }
    }

    async register(scheduleItem) {
        if (!this.isRegistrableTrainee || !(await this.isRegistrable(scheduleItem))) {
            await this.$bvModal.msgBoxOk('Sorry, user is not eligible to register for this session.');
        }
        else {
            //Check for override
            if (scheduleItem.needsOverride) {
                const activeUser = store.getters['userSession/getUser'];
                if (activeUser.isAnAdministrator()) {
                    const check = await this.$bvModal.msgBoxConfirm('The participant you are attempting to ' +
                        'register for a session that requires vaccine verification has not been verified. Would you ' +
                        'like to override the vaccine requirement and register this participant anyway?', {
                        title: 'Vaccine Override Needed',
                        noCloseOnBackdrop: true,
                        noCloseOnEsc: true,
                        headerBgVariant: 'dark',
                        headerTextVariant: 'white'
                    });
                    if (check) {
                        //Set override flag
                        scheduleItem.registration.vaccineOverride = true;
                    } else {
                        return;
                    }
                } else {
                    //A non-admin cannot override
                    return this.$bvModal.msgBoxOk(covidRegistrationRestriction(this), {
                        size: 'md',
                        buttonSize: 'sm'
                    });
                }
            }
            this.registrationRequest = scheduleItem;
            this.registrationRequest.registration.canceled = false;
            this.$bvModal.show(this.confirmRegistrationModalId);
        }
    }

    isCancellable(registrationRequest) {
        if (!!registrationRequest.registration.id && registrationRequest.status === Status.REGISTERED) {
            return registrationRequest.registration.cancelable;
        }
        //Non-registered statuses can't cancel
        else {
            return false;
        }
    }

    async isRegistrable(registrationRequest) {
        if (_.isEqual(registrationRequest.status, Status.SCHEDULED) ||
            _.isEqual(registrationRequest.status, Status.CANCELED)) {
            try {
                await this.$store.dispatch('registration/loadRegistrableSessionsByUser', registrationRequest.user);
            }
            catch (error) {
                this.$bvToast.toast(error.message, errorToastOptions);
            }
            const registrableSessions = this.$store.getters['registration/registrableSessionsByUser'](registrationRequest.user);
            const registrable = _.find(registrableSessions, session => {
                return session.sessionId === registrationRequest.session.sessionId;
            });
            return _.isUndefined(registrable) ? false : true;
        }
        //Non-eligible/non-scheduled statuses can't register
        else {
            return false;
        }
    }

    get selectedTrainee() {
        const trainee = this.$store.getters['trainees/trainee'];
        return trainee;
    }

    get selectedTraineeTrack() {
        return (NULL_USER.equals(this.selectedTrainee) || !this.selectedTrainee.trackId) ?
            null : this.$store.getters['tracks/getTrackById'](this.selectedTrainee.trackId);
    }

    get programFields() {
        return [{
            key: 'name',
            label: 'Program'
        }, {
            key: 'description',
            label: 'Description'
        }, {
            key: 'session',
            label: 'Session'
        }, {
            key: 'status',
            label: 'Status'
        }];
    }

    get activityFields() {
        return [{
            key: 'displayId',
            label: 'Activity',
            sortable: true
        }, {
            key: 'title',
            label: 'Title',
            sortable: true
        }, {
            key: 'description',
            label: 'Description',
            sortable: true
        }, {
            key: 'scheduledDate',
            label: 'Scheduled Date',
            tdClass: 'pl-3',
            sortable: true
        }, {
            key: 'completed',
            label: 'Completed Date',
            sortable: true
        }, {
            key: 'buttons',
            label: ''
        }, {
            key: 'documents',
            label: ''
        }];
    }

    getProgramDescription(session) {
        const programs = this.$store.getters['tracks/getPrograms'];
        const program = _.find(programs, p => {
            return p.idx === session.idx;
        });
        return program.description;
    }

    formatSessionDates(session) {
        const dates = session.dates;
        return null === dates ? '' : sprintf('%s &rarr; %s',
            formatters.date(dates[0], 'M/d/yyyy'),
            formatters.date(dates[1], 'M/d/yyyy')
        );
    }

    statusVariant(status) {
        return Status.variant(status);
    }

    async saveActivity(activity) {
        activity.workspace.scheduled = set(
            endOfMonth(activity.workspace.scheduled),
            { hours: 0, minutes: 0, seconds: 0, milliseconds: 0 });
        try {
            await store.dispatch('activities/processTraineeActivity', activity.workspace);
            activity.commit();
            this.refreshActivitySchedule();
        }
        catch (error) {
            await this.$bvModal.msgBoxOk(error.message, errorModalOptions);
            activity.rollback();
        }
    }

    cancelSaveActivity(activity) {
        activity.rollback();
    }

    async cancelActivityAssignment(activity) {
        const cancelCheck = await this.$bvModal.msgBoxConfirm(sprintf(
            'Are you sure you wish to cancel %s\'s assignment for %s?',
            this.selectedTrainee.fullname, activity.label), {
            title: 'Confirm Activity Assignment Cancellation',
            noCloseOnBackdrop: true,
            noCloseOnEsc: true,
            headerBgVariant: 'dark',
            headerTextVariant: 'white'
        });
        if (!cancelCheck) {
            return;
        }
        //Set cancelled flag
        activity.workspace.traineeActivityCancelled = new Date();
        try {
            await store.dispatch('activities/processTraineeActivity', activity.workspace);
            activity.commit();
            this.refreshActivitySchedule();
        }
        catch (error) {
            await this.$bvModal.msgBoxOk(error.message, errorModalOptions);
        }
    }

    async updateTrainee() {
        if (!this.traineeId) {
            return;
        }
        const trainee = await userDao.getUserById(this.traineeId);
        this.$store.commit('trainees/setTrainee', trainee);
    }

    async mounted() {
        if (!this.traineeId) {
            return;
        }
        await this.updateTrainee();
        //Set selectedTraineeTrack
        const track = await trackDao.getTrackById(this.selectedTrainee.trackId);
        this.$store.commit('tracks/addTrack', track);
    }
}
</script>

<style scoped>
    a.session {
        color: white;
    }
</style>